
import 'slick-carousel';

document.addEventListener('DOMContentLoaded', () => {
    const burger = document.querySelector('.header__burger');
    const nav = document.querySelector('.header__nav');
  
    burger.addEventListener('click', () => {
      burger.classList.toggle('active');
      nav.classList.toggle('active');
    });
    
  });
  

document.addEventListener("scroll", () => {
    const header = document.querySelector(".header");
    if (window.scrollY > 100) {
        header.classList.add("scrolled");
    } else {
        header.classList.remove("scrolled");
    }
});


$(document).ready(function(){
  $('.slick-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,           
    centerMode: false,       
    focusOnSelect: true,        
    variableWidth: true,
    centerPadding: '15px',
    arrows: true, // Включение стрелок
    dots: true,   // Включение буллетов (точек)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
});

